<template>
  <div class="page-review animate__animated animate__fadeIn pt-4">
    <div v-if="!warningSlothPlus">
      <p class="fs-md text-center mt-4 mb-5">
        Over 75,000
        <!-- <span class="animate__animated animate__flash animate__infinite animate__slow"
          >0</span
        > -->
        happy SlothMovers
      </p>

      <!-- Client's Move -->
      <div v-if="this.referrer!=='cExtended'">
        <div v-if="toggleEdit" class="container-client-move mb-2">
        <h4 class="fs-la" style="font-weight: 100">{{ firstName }}'s move</h4>
        <div class="d-flex mb-2 w-100">
          <div :class="`boxes w-100 ps-2`">
            <img
              :src="`${urlBucket}/form/assets/icons/calendar.svg`"
              width="40px"
              height="40px"
              class="mx-2"
            />
            <div>
              <Datepicker
                :value="moveInDate"
                :highlighted="{ dates: [new Date(Date.now())] }"
                @selected="(e) => updateValue('moveInDate', e)"
                id="datepicker"
                class="fs-md"
                :format="formatDate"
              />
              <!-- <p class="mb-0 fs-md">{{ dateMove(moveInDate) }}</p> -->
            </div>
          </div>
        </div>

        <div class="d-flex mb-2 w-100">
          <div :class="`boxes w-100 ps-2 py-1`">
            <img
              :src="`${urlBucket}/form/assets/icons/pin.svg`"
              width="40px"
              height="40px"
              class="mx-2"
            />
            <div>
              <textarea
                type="text"
                placeholder=""
                :value="moveFromAddress"
                @input="
                  (e) => {
                    updateValue('moveFromAddress', e.target.value);
                    autoGrow(e.target);
                  }
                "
              />
              <textarea
                type="text"
                placeholder=""
                :value="moveFromPostCode"
                @input="
                  (e) => {
                    updateValue('moveFromPostCode', e.target.value);
                    autoGrow(e.target);
                  }
                "
              />
            </div>
          </div>
        </div>

        <div class="d-flex mb-2 w-100">
          <div :class="`boxes w-100 ps-2 py-1`">
            <img
              :src="`${urlBucket}/form/assets/bluepin.svg`"
              width="40px"
              height="40px"
              class="mx-2"
            />
            <div>
              <textarea
                type="text"
                placeholder=""
                :value="moveToAddress"
                @input="
                  (e) => {
                    updateValue('moveToAddress', e.target.value);
                    autoGrow(e.target);
                  }
                "
              />
              <textarea
                type="text"
                placeholder=""
                :value="moveToPostCode"
                @input="
                  (e) => {
                    updateValue('moveToPostCode', e.target.value);
                    autoGrow(e.target);
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Box Preview Move -->
        <div v-if="!toggleEdit" class="container-preview-shield p-4 mb-2">
        <div class="container-icon-shield w-100 text-center">
          <div class="icon px-3">
            <img :src="require('@/assets/blue-shield.png')" class="" width="40px" />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h5 style="color: #22a8d6" class="fs-la">Moving from</h5>
            <p class="mb-0 fs-sm">{{ dateMove(moveOutFlag ? moveOutDate : moveInDate) }}</p>
            <p class="mb-0 fs-sm">
              {{ moveFromAddress }}
            </p>
            <p class="mb-0 fs-sm">
              {{ moveFromPostCode }}
            </p>
          </div>
          <div class="col-6 pe-1" style="padding-left: 1.8rem !important">
            <h5 style="color: #94e6bf">Moving to</h5>
            <p class="mb-0 fs-sm">{{ dateMove(moveInDate) }}</p>
            <p class="mb-0 fs-sm">
              {{ moveToAddress }}
            </p>
            <p class="mb-0 fs-sm">
              {{ moveToPostCode }}
            </p>
          </div>
        </div>
      </div>

        <p class="mb-4 text-end fs-sm" style="cursor: pointer" @click="toggleEditMove()">Edit move</p>
      </div>
      <div v-else >
        We will add this new selection to your account
      </div>
      <h4
        v-if="services.length == 0 && !informBroadbandMedia && !informInsurance"
        style="font-weight: 100"
        class="fs-xl my-5"
      >
        You have not selected anything to update
      </h4>

      <!-- Updates -->
      <div class="mb-5" v-else>
        <h4 style="font-weight: 100" class="fs-xl">{{ firstName }} {{ lastName }}'s updates</h4>
        <div class="row mb-2">
          <div
            class="col-4"
            v-for="(item, index) in services"
            :key="index"
            style="padding: 4px 2px"
          >
            <div :class="`d-flex align-items-center justify-content-center m-1 p-1 squares`">
              <div>
                <img
                  :src="`${urlBucket}/${item.icon}`"
                  class="rounded-2"
                  style="display: none"
                  @load="
                    (e) => {
                      e.target.style.display = 'unset';
                      hideSpinner(`spinner-${index}`);
                    }
                  "
                />
                <img
                  src="/assets/img/spinner-loading.gif"
                  :id="`spinner-${index}`"
                  class="img-fluid"
                  width="55px"
                />
                <p class="fw-bold mb-0 fs-sm">{{ item.name ?? item.insurer }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p class="text-end fs-sm text-decoration-underline">Edit updates</p> -->
      </div>

      <div class="mb-5" v-if="services.length > 0 || informBroadbandMedia || informInsurance">
        <!-- <h4 class="fs-la">Summary</h4> -->

        <!-- <div class="box-happens d-flex justify-content-between py-3 px-3 mb-2">
          <div class="d-flex align-items-center">
            <img
              :src="require('@/assets/sloth.png')"
              width="40px"
              class="me-3"
              style="display: none"
              @load="
                (e) => {
                  e.target.style.display = 'unset';
                  hideSpinner(`address-updater`);
                }
              "
            />
            <div id="address-updater" class="text-center me-2">
              <img src="/assets/img/spinner-loading.gif" width="40px" alt="" />
            </div>
            <p class="mb-0 fs-md pe-1">
              Address Updater
              <span v-if="priceOffered == 5 && !referrerValidation()"
                >- Single Selection Discount</span
              >
              <span v-if="priceOffered == 10 && !referrerValidation()"
                >- Double Selection Discount</span
              >
            </p>
          </div>
          <div class="d-flex align-items-center">
            <p v-if="referrerValidation()" class="mb-0 fs-md">Included</p>
            <p v-else class="mb-0 fs-md text-end" style="width: 40px">£ {{ priceOffered }}</p>
          </div>
        </div>

        <div
          class="box-happens d-flex justify-content-between py-3 px-3 mb-2"
          style="background-color: #9dcd5b"
        >
          <div class="d-flex align-items-center">
            <img
              :src="require('@/assets/rainfores.png')"
              width="40px"
              class="me-3"
              alt=""
              style="display: none"
              @load="
                (e) => {
                  e.target.style.display = 'unset';
                  hideSpinner(`rainfores`);
                }
              "
            />
            <div id="rainfores" class="text-center me-2">
              <img src="/assets/img/spinner-loading.gif" width="40px" alt="" />
            </div>
            <p class="mb-0 fs-md text-white">Patch of Rainforest</p>
          </div>
          <div class="d-flex align-items-center">
            <p class="mb-0 fs-md text-white">FREE</p>
          </div>
        </div> -->

        <!-- More Info Toggle -->
        <!-- <div
          class="d-flex align-items-center justify-content-end"
          @click="toggleMoreInfo = !toggleMoreInfo"
        >
          <p class="mb-0 fs-sm text-decoration-underline me-1" style="cursor: pointer">
            What happens next
          </p>
          <i v-if="!toggleMoreInfo" class="fa-solid fa-angle-down" style="font-size: 25px"></i>
          <i
            v-if="toggleMoreInfo"
            class="fa-solid fa-angle-up animate__animated animate__rotateIn animate__faster"
            style="font-size: 25px"
          ></i>
        </div> -->
        <!-- More Info -->
        <!-- <div class="more-info animate__animated animate__fadeIn" v-if="toggleMoreInfo">
          <h4 class="fw-bold fs-md mb-0">Patch of rainforest 🌍</h4>
          <p class="fs-sm mb-3">
            We save a patch of South Caribbean Rainforest with every move. Our part to make a mark for
            our planet.
          </p>

          <h4 class="fw-bold fs-md mb-0">How it works 🦥</h4>
          <p class="fs-sm mb-3">
            Our award-winning address-changer technology is powered by hundreds relationships with
            some of the largest organisations in the UK. SlothMove notifies your chosen organisation
            about your change of address. Each business has different processes and timescales on
            address updates can vary by supplier
          </p>

          <h4 class="fw-bold fs-md mb-0">What happens next 🌍</h4>
          <p class="fs-sm">
            Once you complete your SlothMove you can see the status of your address updates in your
            dashboard. Certain companies such as the DVLA & HMRC may need further information which
            you can complete at any time in your dashboard. Our friendly team of Sloths are here to
            help 7 days a week
          </p>
        </div> -->
      </div>

<!--      <div class="faqs mb-5">
        <h3 class="fs-xl fw-bold mb-4">FAQ's</h3>

        <div class="faq" v-for="(question, index) in faqs" :key="index">
          <div
            class="d-flex justify-content-between align-items-center pt-1 c-pointer"
            @click="question.expand = !question.expand"
          >
            <h4 class="fs-la mb-0" :style="{ color: question.color }">
              {{ question.title }}<span class="ms-2">{{ question.emoji }}</span>
            </h4>

            <i
              class="icon expand mdi mdi-chevron-up animate__animated animate__rotateIn animate__faster"
              v-if="question.expand"
            ></i>
            <i class="icon expand mdi mdi-chevron-down" v-if="!question.expand"></i>
          </div>
          <p v-if="question.expand" class="fs-sm mb-0 animate__animated animate__fadeIn">
            {{ question.desc }}
          </p>
        </div>
      </div>-->

      <!-- SlothMove Selection -->
      <div
        class="slothmove-selection-section"
        v-if="
          referrer !== 'TaylorRose'/* && !this.isCanopyUtilitiesOnly()*/ && referrer !== 'AddressUpdater' && referrer !== 'FamilyUpdater'
        "
      >
        <!-- <div>
          <div class="container-sloth-title mb-4">
            <div class="line"></div>
            <p class="mx-3 mb-0 fs-la fw-bold">Select Your SlothMove</p>
            <div class="line"></div>
          </div>

          <h3 class="fs-la">Which SlothMove you would like?</h3>
          <p class="fs-sm">
            Select between SlothMove or <span style="color: #ffe859">Plus</span> by clicking below
          </p>
        </div> -->

        <!-- Minicards -->
        <!-- <div class="mini-cards-selection row mb-5">
          <div
            class="col me-3"
            :class="{ normal: slothMoveSelection == 'normal' }"
            @click="
              () => {
                updateValue('slothMoveSelection', 'normal');
                updateValue('priceOffered', 35);
                updateValue('isPlus', false);
              }
            "
          >
            <div
              class="d-flex align-items-start justify-content-between mb-2"
              style="margin-top: 10px"
            >
              <img :src="`${urlBucket}/form/logos/slot-profile.png`" width="65px" />

            </div>

            <p class="ps-2 fs-sm mb-0 fw-bold">SlothMove</p>
            <p class="ps-2 fs-sm" style="margin-bottom: 9px">£35</p>
          </div>

          <div
            class="col"
            :class="{ plus: slothMoveSelection == 'plus' }"
            @click="
              () => {
                updateValue('slothMoveSelection', 'plus');
                updateValue('priceOffered', 295);
                updateValue('isPlus', true);
              }
            "
          >
            <div
              class="d-flex align-items-start justify-content-between mb-2"
              style="margin-top: 10px"
            >
              <img :src="`${urlBucket}/form/logos/slot-superman.png`" width="65px" />
            </div>
            <p class="ps-2 fs-sm mb-0 fw-bold">
              SlothMove
              <span style="color: #ffe859">Home</span>
            </p>
            <p class="ps-2 fs-sm" style="margin-bottom: 9px">
              £295
            </p>
          </div>
        </div> -->

        <!-- Cards slothmove-selection -->
<!--        <div v-for="(service, id) in slothMoveServices" :key="id">
          <div
            class="slothmove-selection p-4 mb-4"
            :class="service.class"
            v-if="
              (service.class == 'plus' && slothMoveSelection == 'plus') ||
              (service.class == 'normal' && slothMoveSelection == 'normal')
            "
          >
            <div class="main-icon-container w-100 text-center">
              <div class="icon px-3">
                <img :src="`${urlBucket}/${service.icon}`" class="" height="190px" />
              </div>
            </div>
            <div class="text-center">
              <h3 class="fw-bolder fs-xl">
                SlothMove <span v-if="service.class !== 'normal'" style="color: #ffe859">Home</span>
              </h3>
              <h3 class="fw-bolder fs-xl">
                £{{ service["price"] }}
              </h3>
            </div>

            <div class="description p-3 mb-4">
              <p class="mb-0" style="font-size: 1rem">
                {{ service["description"] }}
              </p>
            </div>

            <div v-if="slothMoveSelection == 'normal'">
              <div
                class="d-flex align-items-center mb-4 px-3"
                v-for="(subItem, i) in service.items"
                :key="i"
              >
                <img
                  class="me-3"
                  :src="require('@/assets/check-sloth-select.png')"
                  width="35px"
                  height="35px"
                  alt=""
                  srcset=""
                />
                <p class="mb-0" style="font-size: 1rem">{{ subItem }}</p>
              </div>
            </div>

            <div v-if="slothMoveSelection == 'plus'">
              <div
                class="d-flex align-items-center mb-4 px-3"
                v-for="(subItem, i) in service.features"
                :key="i"
              >
                <img
                  class="me-3"
                  :src="`${urlBucket}/form/icons/${subItem.icon}.png`"
                  width="55px"
                  height="55px"
                  alt=""
                  srcset=""
                />
                <div>
                  <div class="d-flex align-items-center">
                    <h3
                      class="fw-bold mb-0"
                      :style="{ color: subItem.color }"
                      style="font-size: 1rem"
                    >
                      {{ subItem.name }}
                    </h3>
                    <span class="worth text-white fw-bold"
                      >WORTH
                      <span :style="{ color: subItem.color }">£{{ subItem.worth }}</span></span
                    >
                  </div>
                  <p class="mb-0" style="font-size: 1rem">{{ subItem.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>-->

        <!-- <div v-if="slothMoveSelection == 'plus'" class="months-minimum mb-4">
          <div class="ms-5 line"></div>
          <p class="mx-3 mb-0 fs-xs fw-bold">3 MONTHS MINIMUN</p>
          <div class="me-5 line"></div>
        </div> -->

        <!-- SlothMove Selection -->

        <!-- Summary -->
        <div
          class="box-summary mb-4"
          v-if="
            referrer !== 'TaylorRose' &&
            /*!this.isCanopyUtilitiesOnly()  &&*/
            referrer !== 'AddressUpdater' &&
            referrer !== 'FamilyUpdater'
          "
        >
          <div class="d-flex justify-content-between">
            <h4 class="fs-la mb-0 font-weigth-bold">Order Summary</h4>
            <h4 class="fs-xl mb-0">£{{ priceOffered.toFixed(2) }}</h4>
          </div>
          <!-- <p class="mb-3 fs-sm" style="color: #9d9e9f">Order Number: #380030</p> -->
          <hr />

          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <img
                :src="`${urlBucket}/form/assets/only-sloth-logo.png`"
                width="39px"
                height="45px"
                class="me-3"
                alt=""
                srcset=""
              />
              <div>
                <h4 class="fs-md" style="margin-bottom: 5.5px">ADDRESS CHANGER</h4>
                <p class="mb-0 fs-sm">Quantity: 1</p>
              </div>
            </div>
            <h4 class="fs-md">£{{ priceOffered.toFixed(2) }}</h4>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h4 class="fs-md mb-1" style="color: #393939">Subtotal</h4>
            <h4 class="fs-md mb-1">
              £{{ vatCalculations[`${priceOffered}`].netValue.toFixed(2) }}
            </h4>
          </div>
          <div class="d-flex justify-content-between">
            <h4 class="fs-md mb-1" style="color: #393939">Taxes</h4>
            <h4 class="fs-md mb-1">£{{ vatCalculations[`${priceOffered}`].taxes.toFixed(2) }}</h4>
          </div>
          <!-- <div class="d-flex justify-content-between">
            <h4 class="fs-md mb-1" style="color: #393939">May Mover Bonus x 7</h4>
            <h4 class="fs-md mb-1">£0.00</h4>
          </div> -->

          <hr />

          <div class="d-flex justify-content-between">
            <h4 class="fs-la mb-0 font-weigth-bold">Order Total</h4>
            <h4 class="fs-xl mb-0">£{{ priceOffered.toFixed(2) }}</h4>
          </div>
        </div>

        <!-- Mover Bonus -->
        <div class="mover-bonus" v-if="referrer !== 'TaylorRose'">
          <div v-if="false">
            <h3 class="">{{ getCurrentMonth() }} Mover Bonus:</h3>
            <div class="d-flex align-items-center mb-3">
              <img :src="require('@/assets/timer.png')" class="logo-timer me-2" alt="" srcset="" />
              <h5 class="fw-bold mb-0" style="color: #ff7a7a">
                Expires: <span style="color: white">{{ counter }}</span>
              </h5>
            </div>
            <p class="mb-4 fs-md">
              If you use SlothMove after the bonus offer expires, you are able to purchase these
              bonuses individually
              <!-- Our <span class="">first 500</span> customers get early mover bonuses, once they're gone you
          can purchase them individually -->
            </p>
            <!-- Boxes-Free-Worth -->
            <div class="d-flex mb-4" v-for="(item, index) in worthItems" :key="index">
              <div class="boxes-free px-3" style="width: 80%">
                <h3 class="fs-la fw-bold">{{ item.title }}</h3>
                <p class="mb-1 fs-sm">{{ item.description }}</p>
                <p class="mb-0 fs-md fw-bold">
                  Worth <span style="color: #ff7a7a">{{ item.price }}</span>
                </p>
              </div>
              <div class="d-flex justify-content-center align-items-center" style="width: 20%">
                <p class="free-badge mb-0 fw-bold">FREE</p>
              </div>
            </div>
          </div>

          <img
            :src="`${urlBucket}/form/assets/hanging-sloth-banner-2.png`"
            alt=""
            class="img-fluid mb-3"
            srcset=""
          />
          <!-- Checkboxes -->
          <div class="checkboxes mb-4">
            <!-- <div class="d-flex align-items-center mb-2">
          <p-check
            style="font-size: 22px"
            name="check"
            class="pretty p-icon p-curve p-thick p-jelly conditions"
            color="primary"
            @change="toggleTerms"
          >
            <div class="state">
              <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
            </div>
          </p-check>
          <p class="mb-0 ms-3 fs-xs c-pointer" @click="autoClickCheckbox('conditions')">
            I consent to SlothMove's
            <a href="https://slothmove.com/terms-and-conditions/" target="_blank">terms</a>
            and for SlothMove to update my addresses & set up services where selected. To do this, I
            understand that SlothMove may need to contact me via phone, email or SMS
          </p>
        </div>
        <p v-if="termsChecked == false" class="fs-md" style="color: #c64a45">
          * You must agree to our terms and conditions
        </p> -->
            <div class="d-flex align-items-center">
              <p-check
                style="font-size: 22px"
                name="check"
                class="pretty p-icon p-curve p-thick p-jelly stay-join"
                color="primary"
                @change="toggleCheckJoin"
                :value="campaignChecked"
              >
                <div class="state">
                  <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
                </div>
              </p-check>
              <p class="mb-0 ms-3 fs-xs c-pointer" @click="autoClickCheckbox('stay-join')">
                Stay in touch and join the SlothMovement.The latest deals, guides, tips and tricks
                to help you save money and get ahead 🦥
              </p>
            </div>
          </div>

          <div class="label-pages d-flex p-2 px-2 align-items-center">
            <img :src="require('@/assets/light.png')" width="25px" class="ms-2 me-2" alt="" />
            <p class="ms-1 mb-0 fs-xs">
              We work with hundreds of organisations to make SlothMove magic. You can track the
              update progress in your dashboard
            </p>
          </div>
        </div>
      </div>

      <!-- Oops-Card -->
      <!-- <div class="oops-card p-4" v-if="warningSlothPlus">
        <div class="container-banner w-100 text-center">
          <div class="banner px-3">
            <img
              :src="`${urlBucket}/form/assets/well be back-review.png`"
              class="img-fluid"
              width="260px"
            />
          </div>
        </div>
        <h3 class="fw-bold mb-4 px-1" style="color: #212534">Oops, it's only SlothMove</h3>
        <p class="text-black mb-4 px-1">
          SlothMove Plus is temporarily unavailable for the next five days. By way of an apology, if
          you complete below with SlothMove standard, you can upgrade to Plus with a 50% of
          discount.
        </p>
        <p class="text-black mb-4 px-1">
          One of your moving specialists will reach out to support you with this.
        </p>

        <button
          class="w-100"
          @click="
            () => {
              stripeCheckoutPayment();
            }
          "
        >
          <div class="spinner-border text-light" role="status" v-if="isSending">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else class="fs-la mb-0 fw-bold text-white">
            CONTINUE FOR £{{ priceOffered }}
          </span>
        </button>
      </div> -->
      <p class="fs-md mt-3" style="color: rgb(198, 74, 69)" v-if="errorTryingSubmit">
        * Something went wrong. Please try again in a minute
      </p>

      <OneOffPayment
        ref="refStripeCheckout"
        :email="email"
        :referrer="referrer"
        :priceOffered="priceOffered"
        v-if="setPaymentStripe"
      >
      </OneOffPayment>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment";
import OneOffPayment from "@/components/OneOffPayment";
import emailjs from "emailjs-com";
import Datepicker from "vuejs-datepicker";
import { navigateTo } from "../router/index.js";
import { tiktokAnalytics } from "../utils/tiktokAnalytics.js";
import {deleteRoute} from "../router/setRoute";

// @ is an alias to /src
export default {
  name: "PageReview",
  components: { OneOffPayment, Datepicker },
  data() {
    return {
      toggleMoreInfo: false,
      toggleEdit: false,
      services: [],
      waterSelected: [],
      councilSelected: [],
      // termsChecked: undefined,
      // Payment
      loading: false,
      isSending: false,
      currentStep: "1",
      faqs: [
        {
          title: "What do I get?",
          emoji: "🙌",
          desc: `SlothMove will inform all of your selected providers about address update, saving you
            ${this.timeSaved} hours of time`,
          expand: false,
          color: "#b2e8fa",
        },
        {
          title: " What happens next?",
          emoji: "🤔",
          desc: `We’ll send you a welcome email with a link to your moving dashboard and next steps you need to take`,
          expand: false,
          color: "#dd9595",
        },
        {
          title: " How long will it take?",
          emoji: "🚀",
          desc: `This varies from provider, some will update within 24 hours and other organisations can
          take a few weeks to process`,
          expand: false,
          color: "#dc9edd",
        },
        {
          title: "Do I need to do anything?",
          emoji: "👀",
          desc: `You only need to consent to us processing these for you and answer any supplier questions
          in the dashboard. We'll handle everything else`,
          expand: false,
          color: "#f0fab2",
        },
      ],
      worthItems: [
        {
          title: "Governmental Updater",
          description: "Address updates across any of DVLA, HMRC or DWP",
          price: "£20",
        },
        {
          title: "Home Setup",
          description: "Concierge support to setup home services for utilities and more",
          price: "£290",
        },
        {
          title: "Whatsapp Support",
          description:
            "Upgraded service Chat with SlothMove over whatsapp (or email if you prefer)",
          price: "£10",
        },
        {
          title: "Appliance Discounts",
          description: "2-5% Cash back from John Lewis, B&Q, IKEA, M&S, and more",
          price: "£100's",
        },
        {
          title: "Tenancy deposit checker",
          description: "rental protection report, ensuring the compliant rental setup",
          price: "£25",
        },
        {
          title: "Removal & storage",
          description: "£20 Off any removal or storage facilities with Anyvan, claim any time",
          price: "£20",
        },
        {
          title: "Expedited Move",
          description: "Even faster SlothMove address updates with priority processing",
          price: "£15",
        },
      ],
      slothMoveServices: [],
      vatCalculations: {
        35: {
          taxes: 5.83,
          netValue: 29.17,
        },
        295: {
          taxes: 49.17,
          netValue: 245.83,
        },
      },
      counter: null,
      interval: null,
      setPaymentStripe: false,
      paymentLink: "",
      errorTryingSubmit: false,
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    updateValue(itemName, value) {
      // console.log("updateValue", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    autoGrow(element) {
      let limit = 600; //height limit
      element.style.height = "";
      element.style.height = Math.min(element.scrollHeight, limit) + "px";
    },
    toggleTerms() {
      this.termsChecked = !this.termsChecked;

      // if (this.termsChecked) {
      //   document.getElementById('termsError').innerHTML = ''
      // } else {
      //   document.getElementById('termsError').innerHTML = 'You must agree to our terms and conditions'
      // }
    },
    toggleCheckJoin(e) {
      this.$store.commit("formStore/setField", [`campaignChecked`, e]);
    },
    autoClickCheckbox(className) {
      document.querySelector(`.${className} input[type=checkbox]`).click();
    },
    fillSelectedServices() {
      let arrayTemp = [];

      //Council and Waters

      if (this.informNewCouncil && this.newCouncil)
        this.councilSelected.push({
          icon: `icons/councils/${this.newCouncil}.png`,
          name: this.newCouncil,
        });

      if (this.informNewWater && this.newWater)
        this.waterSelected.push({ icon: `icons/${this.newWater}.png`, name: this.newWater });

      if (this.informOldCouncil && this.oldCouncil)
        this.councilSelected.push({
          icon: `icons/councils/${this.oldCouncil}.png`,
          name: this.oldCouncil,
        });

      if (this.informOldWater && this.oldWater)
        this.waterSelected.push({ icon: `icons/${this.oldWater}.png`, name: this.oldWater });

      // DVLA
      if (this.informDVLA) arrayTemp.push({ icon: `form/assets/DVLA.png`, name: "DVLA" });
      //Dentist
      if (this.informDentist) arrayTemp.push({ icon: "icons/dentist.png", name: "Dentist" });

      //NHS
      if (this.informNHS) arrayTemp.push({ icon: `icons/nhs.png`, name: "NHS" });

      if (this.informHMRC) arrayTemp.push({ icon: `icons/hmrc_black.png`, name: "HMRC" });

      if (this.informDWP) arrayTemp.push({ icon: `icons/DWP.png`, name: "DWP" });
      if (this.informChildBenefit) arrayTemp.push({ icon: `icons/hmrc_black.png`, name: "Child Benefits" });

      if (this.informStudentFinance) arrayTemp.push({ icon: `icons/slc.png`, name: "SLC" });

      if (this.informElectoralRoll)
        arrayTemp.push({ icon: `icons/hmrc_black.png`, name: "Electoral" });

      if (this.informSpam) arrayTemp.push({ icon: `icons/spam.svg`, name: "Spam" });

      // if (this.informBanks && this.suggestedCompanies.length > 0)
      //   arrayTemp.push({ icon: `form/assets/banks.svg`, name: "Banks" });

      if (this.informBonds) arrayTemp.push({ icon: `icons/bonds.png`, name: "Bonds" });

      if (this.informTVLicense)
        arrayTemp.push({
          icon: "icons/tvlicense.png" /* `form/assets/tv.svg` */,
          name: "TV Licence",
        });

      if (this.informLottery)
        arrayTemp.push({ icon: `icons/postcodelottery.png`, name: "Lottery" });

      const removeDuplicates = (arr) => {
        return [...new Set(arr)];
      };

      let subServicesInsurances = removeDuplicates(
        new Array(
          ...[
            ...this.selectedBreakdown,
            ...this.selectedHomecare,
            ...this.insurancesSelection.map((item) => {
              item["name"] = item.insurer;
              item["icon"] = `icons/${item.insurer}.png`;
              delete item["type"];
              return item;
            }),
            ...this.pets
              .map((pet) => {
                return [
                  pet["foodProvider"],
                  ...pet["currentInsurer"].map((item) => {
                    item["name"] = item.insurer;
                    item["icon"] = `icons/${item.insurer}.png`;

                    return item;
                  }),
                  ...pet["fleaWorming"],
                ];
              })
              .flat(),
          ]
        )
      );

      let arrayForMap = [
        ...this.waterSelected,
        ...this.councilSelected,
        ...arrayTemp,
        ...this.selectedBanks,
        ...this.selectedGyms,
        ...this.selectedCharities,
        ...this.pensionSelection,
        ...this.selectedLoyalties,
        ...this.mobileSelection,
        ...this.selectedEnergySupplier,
        ...this.selectedGasSupplier,
        ...this.selectedOpticians,
        ...subServicesInsurances,
      ].filter((item) => item != "");

      arrayForMap.forEach((item) => {
        try {
          if (!this.services.some((uniqueItem) => uniqueItem.name === item.name)) {
            this.services.push(item);
          }
        } catch (error) {
          // console.error(error)
        }
      });

      // this.services = arrayForMap;

      if (this.services.length == 0) {
        this.$store.commit("setField", [`messageButton`, "Go Back"]);
      }
    },
    isCanopyUtilitiesOnly(){
      let r=false;
      if (this.referrer.includes("anopy")){
        if(!this.informDentist &&
          !this.informDWP &&
          !this.informChildBenefit &&
          !this.informDVLA &&
          !this.informHMRC &&
          !this.informStudentFinance &&
          !this.informElectoralRoll&&
          !this.informNHS&&
          !this.informEnergy&&
          !this.informBanks&&
          !this.informLoyalty&&
          !this.informCharities&&
          !this.informMobile&&
          !this.informBreakdown&&
          !this.informBonds&&
          !this.informTVLicense&&
          !this.informLottery&&
          !this.informInsurance&&
          !this.informHomecare&&
          !this.informPets&&
          !this.informOpticians){
          r=true;
        }
        return r;
      }
    },
    setPriceForPaymentLink() {
      let client_reference_id = this.email.replaceAll("@", "SLOTat12").replaceAll(".", "SLOTdot13");
      let params = `?prefilled_email=${this.email}&client_reference_id=${client_reference_id}`;

      this.paymentLink =
        /* £50 */
        window.location.hostname == "localhost"
          ? `https://buy.stripe.com/test_3csdQW22m56Sc2kaEH` /* Test */
          : `https://buy.stripe.com/aEU4iy9XTgtw5kA3cc`; /* Live */

      if (this.priceOffered == 295) {
        this.paymentLink =
          window.location.hostname == `localhost`
            ? `https://buy.stripe.com/test_7sIeV0gXg56Sd6o004` /* Test */
            : `https://buy.stripe.com/7sIcP4c61eloeVabIK`; /* Live */
      }

      this.paymentLink += params;
    },
    stripeCheckoutPayment() {
      if (this.services.length == 0) {
        deleteRoute("/councils");
        navigateTo("/services");
        return;
      }

      // if (this.termsChecked == undefined) {
      //   this.termsChecked = false;
      //   return;
      // }

      this.setPriceForPaymentLink();
      // window.open(this.paymentLink, '_blank'); return; /* for test payment links */

      if (this.isSending /* || !this.termsChecked */) return;

      // this.setPaymentStripe = true;
      // this.$refs.refStripeCheckout.submit(); //<-for tests

      this.updateValue("premium", true);

      const collectedData = { ...this.formattedFormResults };

      // return;

      if (
        this.freeUpdate ||
        this.referrer == "TaylorRose" ||
        /*this.isCanopyUtilitiesOnly() ||*/
        this.referrer == "The Bunch" ||
        this.referrer == "AddressUpdater" ||
        this.referrer == "FamilyUpdater"
      ) {
        this.$store.commit("setField", [`messageButton`, "Completing..."]);
        this.$store.dispatch("submitForm");

        return;
      }

      if (this.termsChecked) {
        this.isSending = true;

        fetch(`${this.baseURL}/processMove/pre`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            sessionId: "",
            collectedData,
          }),
          body: JSON.stringify({
            data: {
              sessionId: "",
              collectedData,
            },
          }),
        })
          .then((res) => {
            //Cookies
            this.setLargeCookie("userdata", JSON.stringify(this.stateComplete));
            //Cookies


            if (this.referrer == "tiktok") {
              tiktokAnalytics(this.email,'CompletePayment', this.priceOffered);
            }

            /* Redirect to stripe */
            if (res.status == 200) {
              console.log('VUELVE DE PRE '+this.ownedLead);
              if ( this.ownedLead) {

                console.log('Payment link with iFrame',JSON.stringify({ stripe: this.paymentLink }));

                window.top.postMessage(JSON.stringify({ stripe: this.paymentLink }), "*");

              } else {
                window.location = this.paymentLink;
              }
            } else {
              throw new Error("Error");
            }

            // this.isSending = false;
            // this.$refs.refStripeCheckout.submit();
          })
          .catch((error) => {
            console.error(error);
            this.isSending = false;
            this.errorTryingSubmit = true;
            // window.location = this.paymentLink;

            // this.$refs.refStripeCheckout.submit();
            // this.setPaymentStripe = false;
          });
      }
    },
    formatDate(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    dateMove(dateParam) {
      const date = moment(dateParam).format("Do MMMM YYYY");
      return moment(dateParam).isValid() ? date : "";
    },
    toggleEditMove() {
      this.toggleEdit = !this.toggleEdit;
    },
    referrerValidation() {
      return (
        this.referrer == "BillingBetterBB" ||
        this.referrer == "TaylorRose" ||
        this.referrer == "The Bunch"
      );
    },
    getCurrentMonth() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date();
      const currentMonth = date.getMonth();
      return months[currentMonth];
    },
    counterMonthMoverBonus() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      // Refresh the counter every second
      this.interval = setInterval(() => {
        let now = new Date().getTime();

        // Calculate the time difference between now and start date
        let distance = lastDay.getTime() - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000); //optional

        // result
        this.counter = `${days} days, ${hours} hours, ${minutes} minutes` /* , seconds ${seconds} [optional] */;

        if (now >= lastDay.getTime()) {
          clearInterval(this.interval);
          // this.counter = "end";
        }
      }, 1000);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    setLargeCookie(name, value, days = 1) {
      var maxChunkSize = 4000;
      var numChunks = Math.ceil(value.length / maxChunkSize);
      for (var i = 0; i < numChunks; i++) {
        var start = i * maxChunkSize;
        var end = start + maxChunkSize;
        var chunk = value.slice(start, end);
        this.setCookie(name + i, chunk, days);
      }
    },
  },
  mounted() {
    if (!this.email) {
      navigateTo("/home");
      return;
    }

    if (this.referrer == "TaylorRose" /*|| this.isCanopyUtilitiesOnly() */) {
      this.updateValue("priceOffered", 0);
    }/*else if (this.referrer.includes("anopy")&&!this.isCanopyUtilitiesOnly()){
      this.updateValue("priceOffered", 35);
    }*/

    this.updateValue("warningSlothPlus", false);
    this.slothMoveServices = [
      {
        price: 295,
        description:
          "SlothMove Address updates are just the beginning. Our hassle-busting technologies save you from boring life admin so you can enjoy life in the easy lane.",
        features: [
          {
            name: "SlothMove",
            description: "Unlimited SlothMove address updates for you and your family",
            icon: 1, ///form/icons/
            worth: "50",
            color: "#20a7d7",
          },
          {
            name: "SlothGuard",
            description:
              this.newResidentialStatus == "I own my home"
                ? "Receive an alert if someone tries to commit title fraud on your property"
                : "We'll make sure your rental deposit is protected properly and setup",
            icon: 2,
            worth: "60",
            color: "#d0056b",
          },
          {
            name: "SlothUtility",
            description: "Update gas, electricity, and water readings by whatsapp or email",
            icon: 3,
            worth: "20",
            color: "#ffe859",
          },
          {
            name: "SlothProtect",
            description: "Know if your personal data appears ina data breach or the dark web",
            icon: 4,
            worth: "25",
            color: "#59ff83",
          },
          {
            name: "Parking Ticket Figther",
            description:
              "We'll help you appeal any parking fine you get to maximise your chances of success",
            icon: 5,
            worth: "65",
            color: "#59fff5",
          },
          // {
          //   name: "SlothQueue",
          //   description: "Never wait on hold with companies, well waitin the queue for you",
          //   icon: 6,
          //   worth: "35",
          //   color: "#ffa459",
          // },
          {
            name: "Life Admin",
            description: "More technologies to save time, beat bureaucracy, and save money",
            icon: 7,
            worth: "400",
            color: "#fb58ea",
          },
        ],
        class: "plus",
        // type: "Homeowner",
        icon: "form/logos/slot-superman.png",
      },
      {
        price: 35,
        description:
          "The classic, award-winning change of address service. Update everyone and track the progress of your updates in your own dashboard",
        items: ["SlothMove change of address", "Dashboard updates", "Live chat support"],
        class: "normal",
        // type: "Normal",
        icon: "form/logos/slot-profile.png",
      },
    ];

    this.$store.commit("setField", ["titleNavbar", `${this.firstName}'s SlothMove`]);

    this.counterMonthMoverBonus();

    this.$store.commit("setField", ["routeAssigned", null]);

    this.fillSelectedServices();

    //  console.log({ ...this.formattedFormResults });

    const validatePage = () => {
      this.stripeCheckoutPayment();
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      // console.log();
      if (
        e.key == "ArrowLeft" &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        this.$router.back();
      }

      if (
        e.key == "ArrowRight" &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        validatePage();
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "freeUpdate",
      "firstName",
      "lastName",
      "email",
      "baseURL",
      "urlBucket",
      "moveToAddress",
      "moveFromAddress",
      "moveToPostCode",
      "moveFromPostCode",
      "moveInDate",
      "moveOutDate",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      "informDentist",
      "informDWP",
      "informChildBenefit",
      "informDVLA",
      "informHMRC",
      "informStudentFinance",
      "informElectoralRoll",
      "informEnergy",
      "informLoyalty",
      "informCharities",
      "informMobile",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informNHS",
      "informSpam",
      "informBonds",
      "informTVLicense",
      "informLottery",
      "informBroadbandMedia",
      "informInsurance",
      "informOpticians",
      "oldCouncil",
      "newCouncil",
      "oldWater",
      "newWater",
      "pensionSelection",
      "mobileSelection",
      "selectedBanks",
      "selectedGyms",
      "selectedCharities",
      "selectedLoyalties",
      "insurancesSelection",
      "dentistName",
      "priceOffered",
      "slothMoveSelection",
      "cardOptionSelected",
      "moveOutFlag",
      "selectedEnergySupplier",
      "selectedGasSupplier",
      "selectedHomecare",
      "selectedBreakdown",
      "selectedOpticians",
      "pets",
      "termsChecked",
      "campaignChecked",
      "timeSaved",
      "newResidentialStatus",
      "isPlus",
      "warningSlothPlus",
      "informBanks",
      "suggestedCompanies",
      "ownedLead"
    ]),
    ...mapGetters(["stateComplete"]),
    ...mapGetters("formStore", ["formattedFormResults"]),
  },
  watch: {},
};
</script>
<style lang="scss">
.page-review {
  .oops-card {
    position: relative;
    border-radius: 35px;
    background-color: #f5fcff;
    padding-top: 190px !important;
    margin-top: 110px;
    margin-bottom: 30px;

    .container-banner {
      position: absolute;
      left: 0%;
      top: -90px;
      display: flex;
      justify-content: center;
    }

    button {
      border-radius: 10px;
      border: none;
      padding: 10px 30px;
      text-align: center;
      text-decoration: none;
      background-color: #00d54f;

      .spinner-border {
        --bs-spinner-width: 1.5rem !important;
        --bs-spinner-height: 1.5rem !important;
      }
    }
  }

  textarea {
    width: 100%;
    height: 20px;
    resize: none;
    outline: none;
    min-height: 20px;
    overflow: hidden;
    border: transparent;
  }

  .vdp-datepicker {
    color: black;

    input {
      width: 100% !important;
    }
  }

  .container-banner {
    position: relative;
    text-align: center;
    color: white;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container-preview-shield {
    position: relative;
    border: 2px solid #22a8d6;
    border-radius: 8px;

    .container-icon-shield {
      position: absolute;
      left: 0%;
      top: -25px;
      display: flex;
      justify-content: center;

      .icon {
        background-color: #1a1d29;
        border-radius: 15px;
      }
    }
  }

  .boxes {
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    height: 168px;

    @media screen and (min-width: 50px) and (max-width: 700px) {
      /*height: calc(100vw - 168px);*/
      height: 100%;
    }

    img {
      width: 90%;
    }
  }

  .container-sloth-title {
    display: flex;
    align-items: center;

    .line {
      flex-grow: 1;
      height: 0;
      border-top: 3px dashed white;
    }
  }

  .mini-cards-selection {
    .col {
      cursor: pointer;
      max-width: 190px;
      outline: 3px solid #cecece;
      border-radius: 30px;
      background-color: black;
    }

    .normal {
      outline: 5px solid #23a9d7;
    }

    .plus {
      outline: 5px solid #f7ff67;
    }

    .most-popular {
      display: inline-block;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 30px;
      background-color: #ffebff;
      color: #212534;
      p {
        font-size: 8px;
      }
    }
  }

  .slothmove-selection {
    background-color: black;
    border-radius: 30px;
    margin-top: 140px;

    .description {
      background-color: #2e2c32;
      border-radius: 10px;
      text-align: center;
    }

    .main-icon-container {
      position: relative;
      padding-top: 90px !important;

      .icon {
        position: absolute;
        left: 0%;
        top: -122px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .select-button {
      border-radius: 10px;
      border: none;
      color: black;
      padding: 10px 30px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      opacity: 1;
      transition: opacity 0.2s;
      letter-spacing: 1.7px;
    }
  }

  .slothmove-selection.normal {
    outline: 5px solid #23a9d7;

    .select-button {
      background-color: #23a9d7;
    }

    .select-button:hover {
      background-color: #23a9d7;
      opacity: 0.8;
    }
  }

  .slothmove-selection.plus {
    outline: 5px solid #f7ff67;

    .select-button {
      background-color: #f7ff67;
    }

    .select-button:hover {
      background-color: #f7ff67;
      opacity: 0.8;
    }

    .worth {
      background-color: #2e2c31;
      border-radius: 3px;
      padding: 0px 5px;
      font-size: 10px;
      margin-left: 8px;
    }
  }

  /*
  .slothmove-selection.selected {
    border: 3px solid #04b125;
    .select-button {
      background-color: #04b125;

      p {
        color: #f5fcff;
      }
    }
  }
  */

  .months-minimum {
    display: flex;
    align-items: center;
    margin-top: 35px;

    .line {
      flex-grow: 1;
      height: 0;
      border-top: 1px solid white;
    }
  }

  .box-summary {
    padding: 1.4rem !important;
    padding-top: 1.9rem !important;
    padding-bottom: 1.2rem !important;
    background-color: white;
    border-radius: 20px;
    color: black;
    hr {
      margin: 1.5rem 0;
    }
  }

  .logo-timer {
    width: calc(1.275rem + 0.3vw);
  }

  .boxes-free {
    border-radius: 15px;
    background-color: black;
    padding: 16px;
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important;
  }

  .free-badge {
    background-color: #800080;
    padding: 6px;
    color: #ffbfff;
    border-radius: 6px;
  }

  .minicard {
    border-radius: 15px;
    background-color: #f5fcff;
    margin-bottom: 40px;
  }

  .box-happens {
    background-color: #fff;
    border-radius: 7px;
    color: black;
  }

  .minicard-2 {
    border-radius: 15px;
    background-color: #f5fcff;
    margin-bottom: 40px;

    span {
      cursor: pointer;
      padding-top: 2px;
      padding-bottom: 3px;
      padding-right: 8px;
      padding-left: 8px;
      background-color: #b4b4b4;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    span:hover {
      background-color: #23a9d7;
    }

    span.current {
      background-color: #23a9d7;
    }

    img {
      width: 40%;
      height: 130px;
    }
  }

  @media screen and (min-width: 50px) and (max-width: 500px) {
    .minicard-2 {
      img {
        width: 40%;
        height: 100px;
      }
    }
  }

  .faqs {
    .expand:before {
      font-size: 51px;
      line-height: normal;
      padding-right: 5px;
    }

    .faq {
      margin-bottom: 15px;
      background-color: black;
      border-radius: 20px;

      h4 {
        padding-left: 22px;
      }
      p {
        padding: 0px 22px 22px 22px;
        line-height: 23px;
      }
      /*
      padding-left: 20px;
      padding-bottom: 20px;
      */
    }
  }

  .pretty {
    border-radius: 5px;
    margin-right: 0px;

    .state label:before {
      border-color: #bdc3c7;
      background-color: white;
    }
  }

  .more-info {
    text-align: justify;
  }
}
</style>
